<template>
<v-app>
    <ProfileAppBar title="ข้อมูลสุขภาพ" :backward="true"/>
    <v-main class="natural lighten-4 fill-height">
      <v-container fluid >
              <v-row>
       <v-col cols="12">
          <label class="primary--text text-title-3">บันทึกสุขภาพ</label>
       </v-col>
        <v-col cols="12" v-if="!loading">
          <BPMCard v-bind="bpmData" @onSave="onSaveBPMMethod()"/>
       </v-col>
       <v-col cols="12" v-if="!loading">
         <GlucoseCard v-bind="glucoseData" @onSave="onSaveGlucoseMethod()"/>
       </v-col>
        <v-col cols="12" v-if="!loading">
          <OxymetryCard v-bind="oxymetryData" @onSave="onSaveOxyenMethod()"/>
       </v-col>
       <v-col cols="12" v-if="!loading">
         <BodyCompoCard v-bind="bodyComponentData" @onSave="onSaveBodyCompoMethod()"/>
       </v-col>
       <v-col cols="12" v-if="!loading">
         <TemperatureCard v-bind="temperatureData" @onSave="onSaveTemperatureMethod()"/>
       </v-col>
              </v-row>
     </v-container>
      <v-dialog v-model="methodDialog" persistent>
      <SelectMethodPHCard @onClose="methodDialog=false" :vType="vType"/>
      </v-dialog>
            <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
        </v-main>
      
</v-app>
</template>

<script>
import ProfileAppBar from "@/components/common/ProfileAppBar";
import {postVitalSign, } from "@/api/"
import { getVSign } from "@/api/cms"
import BPMCard from "@/components/healthrecord/BPMCard"
import GlucoseCard from "@/components/healthrecord/GlucoseCard"
import OxymetryCard from "@/components/healthrecord/OxymetryCard"
import BodyCompoCard from "@/components/healthrecord/BodyCompoCard"
import TemperatureCard from "@/components/healthrecord/TemperatureCard"
import SelectMethodPHCard from "@/components/healthrecord/SelectMethodPHCard"
// import EmptyFriendStateCard from "@/components/addfriend/EmptyFriendStateCard"
//import FriendList from "@/components/addfriend/FriendList"
export default {
  name: "HealthRecord",
  components: {
    ProfileAppBar,
    BPMCard,
    SelectMethodPHCard,
    GlucoseCard,
    OxymetryCard,
    BodyCompoCard,
    TemperatureCard
  },
  data() {
    return {
             state: false,
             imageProfile: null,
             loading: false,
             bpmData: {},
             temperatureData: {},
             bodyComponentData: {},
             glucoseData: {},
             oxymetryData: {},
             methodDialog: false,
             vType: null
    }
  },
  methods: {
    onSaveBPMMethod() {
      // console.log('onSave')
      //this.vType = 'blood_pressure'
      this.$router.push({ name: 'blood_pressure'})
    },
    onSaveBodyCompoMethod() {
      this.$router.push({ name: 'body_component'})
    },
    onSaveOxyenMethod() {
      this.$router.push({ name: 'oxymetry'})
    },
    onSaveTemperatureMethod() {
      this.$router.push({ name: 'temperature'})
    },
    onSaveGlucoseMethod() {
      this.$router.push({ name: 'glucose'})
//        postVitalSign({ vType: "temperature",
//     vTimestamp: new Date().getTime(),
//     temperature: 37,
//     measureMode: "M",},message=>{
//       this.loading = false
//       if(message.data.code ===1){
// this.fetchData()
//       }
      
//     },error=>{
//        console.log(error)
//     })
      //this.vType = 'glucose'
      //this.$router.push({ name: this.vType })
    },
    postVitalSign () {
      this.loading = true
      postVitalSign({ vType: "blood_pressure",
    vTimestamp: 1622797189,
    systolic: 120,
    diatolic: 80,
    pulse: 70,
    measureMode: "M",
    remark: "วัดเช้า ตื่นนอน 6:00 น."},message=>{
      this.loading = false
      if(message.data.code ===1){
this.fetchData()
      }
      
    },error=>{
       console.log(error)
    })
    },
    async fetchData() {
      try {
        this.loading = true
        // const site = this.$offlineStorage.get("site");
        // const vital = await getVitalSign({groupId: (site && site.groupId)? site.groupId: ''})
        const vital = await getVSign()
        if (vital.data) {
          const bp = vital.data.blood_pressure
          if(bp.length > 0)this.bpmData = bp[bp.length-1]
          const temp = vital.data.temperature
          if(temp.length >0)this.temperatureData = temp[temp.length-1]
           const body = vital.data.body_component
          if(body.length >0)this.bodyComponentData = body[body.length-1]
           const glucose = vital.data.glucose
          if(glucose.length >0)this.glucoseData = glucose[glucose.length-1]
          const oxymetry = vital.data.oxymetry
          if(oxymetry.length >0)this.oxymetryData = oxymetry[oxymetry.length-1]

          // this.temperatureData = vital.data.result.filter(item=> item.vType === 'blood_pressure')[0]
          // console.log(vital.data.result)
        }
      }catch (error){
        console.log(error)
      }finally{
        this.loading = false
      }
    }
  },
  mounted () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
   }
};
</script>

<style lang="scss" scoped>


</style>