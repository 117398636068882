<template>
  <v-card>
      <v-card-title class="primary--text text-h6">บันทึกข้อมูลสุขภาพ<v-spacer/><v-btn icon color="primary" @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
        <v-row>
            <v-col cols="12">
                <MethodCard v-if="isOnline" :title="'ถ่ายภาพเพื่อบันทึกค่า'" filename="scaninput.svg" @onClick="gotoCamera"/>
             </v-col>
              <v-col cols="12">
                <MethodCard :title="'บันทึกข้อมูลของท่าน'" filename="keyinput.svg" @onClick="gotoInfo"/>
             </v-col>
        </v-row>
      </v-card-text>
  </v-card>
</template>

<script>
import MethodCard from "@/components/formlist/MethodCard"

export default {
 props: {
     vType: {
         type: String
     },
 },
  components: {
    MethodCard,
  },
  methods: {
    gotoCamera() {
      this.$offlineStorage.set('currentForm',this.form)
      //this.citizenObj = {}
      this.$router.push({ name: 'onboarding' })
    },
    gotoInfo(){
       this.$router.push({ name: this.vType })
    }
  },
}
</script>
<style lang="scss" scoped>

</style>