<template>
   <v-card height="72" elevation="0"  @click="$emit('onClick')" :style="{
                    border: active? '2px solid var(--v-primary-base) !important' : '1px solid var(--v-primary-base) !important'
                    }">
                        <v-card-text class="pa-2">
                          <v-row >
                              <v-col cols="3">
                                  <v-avatar size="56" >
                                      <v-img :src="require(`@/assets/${filename}`)"></v-img>
                                  </v-avatar>
                              </v-col>
                              <v-col cols="9" align-self="center">
                                  <div class="text-subtitle-1">{{title}}</div>
                              </v-col>
                          </v-row>
                 </v-card-text>
                 </v-card>
</template>

<script>
export default {
props: {
        title: {
            type: String,
            default:'' 
        },
        filename: {
            type: String,
            default:''
        },
        active: {
            type: Boolean,
            default: false,
        }
    },
}
</script>

<style lang="scss" scoped>
 .v-card {
    border-radius: 6px;
    }
</style>