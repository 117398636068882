<template>
     <v-app-bar app height="90" elevation="0"  color="primary" class="rounded-br-xl" >
          <v-btn style="align-self: flex-end" color="transparent" text :to="{name: 'main'}" class="pa-0 ma-0" ><span class="text-subtitle-1 natural--text">กลับหน้าแรก</span></v-btn>
         <v-spacer/>
         <v-img style="align-self: flex-start" contain height="20" :src="require('@/assets/whitelogo.svg')"/>
         <v-spacer/>
         <v-spacer/>
         <v-avatar size="63" @click="$router.push({name: 'setting'})" >
           <v-img :src="imageProfile || require('@/assets/whiteavatar.svg')" @error="($auth.idpPicture)? imageProfile = $auth.idpPicture : imageProfile = require('@/assets/whiteavatar.svg')"></v-img>
         </v-avatar>
     </v-app-bar>
</template>

<script>
import {getProfilePicture} from "@/api"

export default {
  props: {
    title: {
      type: String,
      default: 'หัวข้อแสดงผล H4' 
    },
    backward: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      role: null,
      imageProfile: null
    }
  },
  methods: {
    fetchData() {
       this.role = this.$offlineStorage.get('role')
       this.loading = true
      getProfilePicture({profilePicture: this.role.profilePicture},message=>{
        if(message.data.code===1){
            this.imageProfile = message.data.result
        }
        this.loading=false
      },error=>{
        console.log(error)
        })
    }
  },
   created () {
     this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  }

}
</script>

<style lang="scss" scoped>
    .v-application .rounded-br-xl {
    border-bottom-right-radius: 24px !important;
  }

</style>