<template>
   <v-card>
           <v-list>
                 <v-list-item>
                       <v-list-item-avatar size="30" tile >  
                           <v-img contain
                      :src="require('@/assets/water-plus.png')"
                    ></v-img></v-list-item-avatar>
                  <v-list-item-content @click="$router.push({name:'glucosehistory'})">
                     <v-list-item-title class="text-title-2 text-wrap">น้ำตาลในเลือด</v-list-item-title>
                      <v-list-item-subtitle class="text-title-3">{{glucose_condensation}} <span>mg/dL</span></v-list-item-subtitle>
                 </v-list-item-content>
               <v-list-item-action>
                   <v-btn outlined color="#F2F2F5" @click="$emit('onSave')"><span class="primary--text">บันทึก</span></v-btn>
                      <v-list-item-action-text v-if="device_timestamp"><v-icon color="natural darken-2">mdi-calendar-clock</v-icon> {{ $dayjs(new Date(device_timestamp*1000) ).local($i18n.locale).format('DD MMMM YYYY HH:mm')  }}</v-list-item-action-text>
               </v-list-item-action>
             </v-list-item>
           </v-list>
       </v-card>
</template>

<script>
export default {
    props: {
        glucose_condensation: {
            type: Number,
            default: 0
        },
        bsTest: {
            type: Object,
            default: ()=>{}
        },
        hb1c: {
            type: Number,
            default: 0
        },
        device_timestamp: {
            type: Number,
            default: 0
        }
    },

}
</script>

<style>

</style>